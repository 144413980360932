import React from "react"
import { navigate } from "gatsby"

import "./ButtonAction.css"

// export interface ButtonActionProps {
//   text: string
//   page: string
//   className?: string
// }

const ButtonAction = ({ text, page, darkButton }) => {
  const onClick = () => navigate(page)

  return (
    <button
      type="button"
      onClick={() => onClick()}
      className={darkButton ? "ButtonDark" : "Button"}
    >
      {text}
    </button>
  )
}

ButtonAction.defaultProps = {
  darkButton: false,
}

export default ButtonAction
