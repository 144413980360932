import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import "./ServicesCards.css"

const ServicesCards = () => {
  const query = useStaticQuery(graphql`
    query {
      allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "services"}}) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                serviceCards {
                  name
                  text
                  icon {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const data = query.allFile.edges[0].node.childMarkdownRemark.frontmatter

  const Card = ({ name, text, Image }) => (
    <div className="ServicesCard">
      <div className="front">
        <div className="ServicesCardImage">
          <img src={Image} alt="Decorative image." />
        </div>
        <h5>{name}</h5>
      </div>
      <div className="back">
        <h5>{name}</h5>
        <p>{text}</p>
      </div>
    </div>
  )

  return (
    <div className="ServicesCardsBar">
      {data.serviceCards.map(C => (
        <Card
          name={C.name}
          text={C.text}
          Image={C.icon.publicURL}
        />
      ))}
    </div>
  )
}

export default ServicesCards
