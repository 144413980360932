import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import ButtonAction from "../ButtonAction"

import "./ContactBar.css"

const ContactBackground = () => {
  const query = useStaticQuery(graphql`
    query {
      allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "websiteFooter"}}) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                contactBar {
                  heading
                  text
                  contactButtonText
                  backgroundImage {
                    childImageSharp {
                      fluid(quality: 90) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const data = query.allFile.edges[0].node.childMarkdownRemark.frontmatter

  return (
    <div className="ContactBarContainer">
      <BackgroundImage
        Tag="section"
        fluid={data.contactBar.backgroundImage.childImageSharp.fluid}
        className="ContactBackground"
        backgroundColor="#040e18"
      />
      <div className="ActionBox">
        <h1>{data.contactBar.heading}</h1>
        <p>{data.contactBar.text}</p>
        <ButtonAction
          text={data.contactBar.contactButtonText}
          page="/contact"
        />
      </div>
      <div className="Filter" />
    </div>
  )
}

export default ContactBackground
