import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import HeroBox from "../components/HeroBox"
import LayoutInner from "../components/LayoutInner"
import Description from "../components/Description"
import FullWidthBar from "../components/FullWidthBar"
import ServicesCards from "../components/ServicesCards"
import CarouselServices from "../components/CarouselServices"
import ContactBar from "../components/ContactBar"

export const query = graphql`
  query {
    allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "services"}}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              headerTitle
              headerSubtitle
              paragraph1Heading
              paragraph1Text
            }
          }
        }
      }
    }
  }
`

const ServicesPage = ( props ) => {
  const data = props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  return (
    <>
      <Layout>
        <Seo title="Our Services" />
        <HeroBox heading={data.headerTitle} text={data.headerSubtitle} />
        <LayoutInner>
        <Description heading={data.paragraph1Heading} text={data.paragraph1Text} smallHeading={false} />
        <br /><FullWidthBar>
          <ServicesCards />
        </FullWidthBar>
        <CarouselServices />
        </LayoutInner>
        <ContactBar />
      </Layout>
    </>
  )
}

export default ServicesPage
