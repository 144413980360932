import React from "react"

import "./FullWidthBar.css"

const FullWidthBar = ({ children }) => {
  return (
    <div className="FullWidth">
      <div className="Inner">
        <main>{children}</main>
      </div>
    </div>
  )
}

export default FullWidthBar
